(function($) {
    "use strict"; // Start of use strict

    $ = jQuery;

    // document.addEventListener('DOMContentLoaded', function () {
    //     LazyBrodawg.init({});
    // });

    $(document).ready(function() {
        $('.popup-gallery').magnificPopup({
            delegate: 'a',
            type: 'image',
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0,1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.'
            }
        });

        $('.image-popup').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            mainClass: 'mfp-img-mobile',
            image: {
                verticalFit: true
            }

        });

        $('[data-load-trigger]').click(function(event) {
            event.preventDefault();
            $(this).toggleClass('is-active');
            $('#more-photos').fadeToggle('400');
        });

    });

})(jQuery); // End of use strict
