(function($) {
    "use strict"; // Start of use strict

    var slider = function() {
        var $slickElement = $('[data-js-slider-one]'),
            $slickElementTwo = $('[data-js-slider-two]');

        $slickElement.length && $slickElement.slick({
            infinite: true,
            prevArrow: '<button type="button" class="slick-prev"><i class="cs-arrow-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="cs-arrow-right"></i></button>',
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        dots: true
                    }
                }
            ]
        });

        $slickElementTwo.length && $slickElementTwo.slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            prevArrow: '<button type="button" class="slick-prev"><i class="cs-arrow-left"></i></button>',
            nextArrow: '<button type="button" class="slick-next"><i class="cs-arrow-right"></i></button>',
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4
                    }
                }
            ]
        });

    }(); // End of slider

})(jQuery); // End of use strict
