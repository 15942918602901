(function($) {
    "use strict"; // Start of use strict

    //Globals
    var $window = $(window),
        $header = $('.site-header'),
        $aside = $('[data-sticky]'),
        $banner = $('[data-js-scroll-banner]'),
        $main = $('.main'),
        windowDidScroll = false;

    if($banner.length > 0) {
        var breakPoint = $banner.offset().top + $banner.height() * 0.75,
            breakPointMain = $main.height();

        $window.bind('scroll load', function(){
            windowDidScroll = true;
        });

        //Setting an interval for performance purpose
        setInterval(function () {
            if (windowDidScroll) {
                windowDidScroll = false;
                scroll_spy();
            }
        }, 0);
    }

    function scroll_spy() {

        var windowTop = $window.scrollTop(),
            asideOffsetTop = 146,
            asideBottom = windowTop + $aside.height() + asideOffsetTop,
            breakPointAside = $banner.height() - $header.outerHeight(true);

        if (windowTop >= 1 && !$header.hasClass('is-smaller')) {
            $header.addClass('is-smaller');
        } else if (windowTop <= 1 && $header.hasClass('is-smaller')) {
            $header.removeClass('is-smaller');
        }

        if (windowTop >= breakPoint && !$header.hasClass('is-full')) {
            $header.addClass('is-full');
        } else if (windowTop <= breakPoint && $header.hasClass('is-full')) {
            $header.removeClass('is-full');
        }
        
        if (windowTop < breakPointAside && $aside.hasClass('is-sticky')) {
            $aside.removeClass('is-sticky');
        } else if (windowTop > breakPointAside && asideBottom < breakPointMain && !$aside.hasClass('is-sticky')) {
            $aside.addClass('is-sticky').removeClass('is-bottom').css('top',asideOffsetTop+'px');
        } else if (asideBottom > breakPointMain && !$aside.hasClass('is-bottom')) {
            $aside.removeClass('is-sticky').addClass('is-bottom').css('top','auto');
        }
    }

})(jQuery); // End of use strict
