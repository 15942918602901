(function($) {
    "use strict"; // Start of use strict

    var $body = $('body'),
        $navIcon = $('.nav-icon'),
        $navMain = $('.menu'),
        $menuToggleItem = $('[data-js-toggle-main-menu]');

    $menuToggleItem.on('click', function(event){
        event.preventDefault();
        $body.toggleClass('has-nav-main-open');
        $navIcon.toggleClass('is-opened');
        $navMain.toggleClass('is-opened');
    });

})(jQuery); // End of use strict
